import { sha256 } from "js-sha256";
import { Link } from "react-router-dom";

const ArticleCard = ({
  categoryArr,
  id,
  title,
  description,
  media,
  hasImage,
}) => {
  const getTitleSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const stripHtml = (text) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = text;
    return tmp.textContent || tmp.innerText || "";
  };

  return (
    <div
      // data-aos="fade-up"
      className="flex flex-row border border-2 border-slate-200 justify-between p-5 my-5 rounded-md"
    >
      <div className="flex flex-col w-full mr-4">
        <div className="flex flex-row py-2 gap-3 items-center flex-wrap">
          {categoryArr.slice(0, 3).map((cat, i) => {
            return (
              <p className="px-3 rounded-2xl bg-gray-300 p-2" key={i}>
                {cat}
              </p>
            );
          })}
        </div>
        <div>
          <p className="font-bold text-[30px] hover:text-blue-500 hover:underline">
            <Link to={`/blog-detail/${btoa(id)}/${getTitleSlug(title)}`}>
              {title}
            </Link>
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: stripHtml(description),
            }}
            className="text-sm my-2"
          ></div>
        </div>
      </div>
      {hasImage ? (
        <div className="w-full my-auto flex items-center">
          <img
            alt="news-image"
            src={media}
            className="h-full w-full object-contain rounded-lg"
          />
        </div>
      ) : null}
    </div>
  );
};

export default ArticleCard;
