import { GeneratePublicToken } from "../components/utils/GeneratePublicToken";
import axios from "axios";

export const getArticleList = async () => {
  const TokenAuth = GeneratePublicToken('landing').Authorization;
  const TokenAuthID = GeneratePublicToken('landing')["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_BACKSTAGE}marketing/news`,
      data: {
        title: "",
        category_id: 0,
        status: 1,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};

export const getArticleDetail = async (id) => {
  const TokenAuth = GeneratePublicToken('landing').Authorization;
  const TokenAuthID = GeneratePublicToken('landing')["Authorization-ID"];

  try {
    const config = {
      headers: {
        Authorization: TokenAuth,
        "Authorization-ID": TokenAuthID,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_BACKSTAGE}marketing/news/detail`,
      data: {
        news_id: id,
      },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err;
  }
};
