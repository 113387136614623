import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { getArticleDetail, getArticleList } from "../../services/articleAPI";
import Seo from "../Seo";

const DetailArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const getTitleSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const { mutate: mutateDetailArticle, data: dataArticle } = useMutation({
    mutationFn: getArticleDetail,
  });

  const { mutate: mutateArticleList, data: dataArticleList } = useMutation({
    mutationFn: getArticleList,
  });

  useEffect(() => {
    const bodyMobile = document.getElementById("article-body");
    const body = document.getElementById("article-body-desktop");

    if (body !== null && dataArticle !== null) {
      bodyMobile.innerHTML += dataArticle.description;
      body.innerHTML += dataArticle.description;
    }
  }, [dataArticle]);

  useEffect(() => {
    mutateArticleList();
    mutateDetailArticle(atob(id));
  }, [id]);

  return (
    <div>
      <style>
        {`h1, h2, h3, h4, h5, h6 {
               font-weight: revert;
               font-size: revert;
            }`}
      </style>

      <div>
        <Navbar />
      </div>
      {dataArticle ? (
        <div className="flex">
          <Seo
            title={dataArticle.title}
            description={dataArticle.meta}
            name={"OLIN"}
            canonLink={"https://olin.co.id"}
          />
          <div className="mobile flex flex-col md:flex-row block md:hidden">
            <div className="w-full">
              <img
                src={dataArticle.media}
                className="w-full object-contain w-full"
                alt=""
              />
            </div>
            <div className="flex gap-3 mt-3 p-3 flex-wrap">
              {dataArticle.category_name.map((cat) => {
                return (
                  <p className="px-3 rounded-2xl bg-gray-300 p-2 ">{cat}</p>
                );
              })}
            </div>
            <div className="p-3">
              <p className="font-bold text-2xl p-3">{dataArticle.title}</p>
              <div id="article-body" className="!h-fit"></div>

              <div>
                <hr className="border-1 border-slate-200 rounded-md my-7" />
                <p className="font-bold text-left p-3">Artikel Lainnya</p>
                <div className="flex flex-col block md:hidden items-center w-full">
                  {dataArticleList
                    ?.filter((item) => item.id !== Number(atob(id)))
                    .map((item) => {
                      return (
                        <div className="flex flex-row  border-2 border-slate-200 rounded-md my-2 items-center">
                          <div className="flex flex-col p-3 basis-3/4">
                            <div className="flex gap-3">
                              {item.category_name.slice(0, 2).map((cat) => {
                                return (
                                  <p className="px-3 rounded-2xl bg-gray-300 p-2 text-[12px]">
                                    {cat}
                                  </p>
                                );
                              })}
                            </div>
                            <p
                              className="text-lg font-bold text-[13px] mt-2 hover:underline hover:text-blue-500 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/blog-detail/${btoa(item.id)}/${getTitleSlug(
                                    item.title
                                  )}`
                                )
                              }
                            >
                              {item.title}
                            </p>
                            <p>{removeTags(item.description)}</p>
                          </div>
                          <div className="h-[20vh] w-full object-cover basis-1/4">
                            <img
                              src={item.media}
                              alt=""
                              className="p-3  object-cover h-full w-full"
                            />
                          </div>
                        </div>
                      );
                    })}

                  <button
                    className="border-2 border-slate-200 rounded-md p-3 px-10 my-5 items-center justify-center"
                    onClick={() => navigate("/blog")}
                  >
                    Artikel Lainnya
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="desktop flex flex-col hidden md:flex">
            <div className="flex flex-col md:flex-row pr-10">
              <div className="w-[100%] md:w-[70%]">
                <div className="w-full">
                  <img
                    src={dataArticle.media}
                    className="h-full w-full object-contain w-full"
                    alt=""
                  />
                </div>
                <div className="w-full p-5 ml-10">
                  <div className="flex flex-row gap-3 flex-wrap">
                    {dataArticle.category_name.map((cat) => {
                      return (
                        <p className="px-3 rounded-2xl bg-gray-300 p-2">
                          {cat}
                        </p>
                      );
                    })}
                  </div>

                  <div className="text-[20px]">
                    <h1 className="font-bold text-[30px] my-5">
                      {dataArticle.title}
                    </h1>
                    <div id="article-body-desktop" className="ql-editor"></div>
                  </div>
                </div>
              </div>

              <div className="w-[30%] hidden md:block mt-5 p-5">
                <div className="bg-slate-100 rounded-md">
                  <div className="flex flex-row items-center justify-center gap-3 p-4">
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=100087468924959"
                      rel="noreferrer"
                      className="bg-slate-200 rounded-full w-12 h-12 p-2"
                    >
                      <BsFacebook className="w-full h-full text-3xl text-[#3b5998] hover:text-slate-700" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/olin_indonesia/"
                      rel="noreferrer"
                      className="bg-slate-200 rounded-full w-12 h-12 p-2"
                    >
                      <BsInstagram className="w-full h-full text-3xl text-[#E1306C] hover:text-slate-700" />
                    </a>

                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UC_qBcIbgRIYCR0aIPDD7_Bw"
                      rel="noreferrer"
                      className="bg-slate-200 rounded-full w-12 h-12 p-2"
                    >
                      <BsYoutube className="w-full h-full text-3xl text-[#FF0000] hover:text-gray-700" />
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/olin-all-in-one-pharmacy-management-system/mycompany/"
                      rel="noreferrer"
                      className="bg-slate-200 rounded-full w-12 h-12 p-2"
                    >
                      <FaLinkedinIn className="w-full h-full text-3xl text-[#0072b1] hover:text-gray-700" />
                    </a>
                  </div>
                </div>
                <div className="flex flex-col mt-5">
                  <p className="font-bold text-2xl">Article Lainnya</p>
                  <hr className="text-black border-1 border-slate-200 rounded-md" />
                  {dataArticleList
                    ?.filter((item) => item.id !== Number(atob(id)))
                    .map((item) => {
                      return (
                        <div className="w-full">
                          <div className="flex flex-row border border-slate-200 rounded-md justify-between p-5 my-5 w-full flex-wrap">
                            <div className="flex flex-row min-w-min py-2 gap-3 items-center flex-wrap">
                              {item.category_name.slice(0, 3).map((cat) => {
                                return (
                                  <p className="px-3 rounded-2xl bg-gray-300 p-2">
                                    {cat}
                                  </p>
                                );
                              })}
                            </div>
                            <div className="w-full">
                              <p
                                className="font-bold text-[20px] hover:underline hover:text-blue-500 cursor-pointer truncate"
                                onClick={() =>
                                  navigate(
                                    `/blog-detail/${btoa(
                                      item.id
                                    )}/${getTitleSlug(item.title)}`
                                  )
                                }
                              >
                                {item.title}
                              </p>
                              <p className="mt-4 text-ellipsis overflow-hidden w-full h-[15vh] text-clip">
                                {removeTags(item.description)}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="mt-12">
        <Footer />
      </div>
    </div>
  );
};

export default DetailArticle;
